import React from 'react'
import { graphql } from 'gatsby'
import { useStoryblok } from '@/storyblok/hooks/useStoryblok'
import { TEMPLATE_TYPES } from '@/constants'

/**
 * Catch all blueprint for storyblok pages
 */

const Blueprint = ({ data, location, pageContext }) => {
  const story = useStoryblok(data.story, location)
  const Template = TEMPLATE_TYPES[data.story.field_component]

  return <Template story={story} pageContext={pageContext} />
}

export default Blueprint

export const query = graphql`
  query StoryblokEntryByID($id: String!) {
    story: storyblokEntry(id: { eq: $id }) {
      id
      name
      content
      slug
      uuid
      full_slug
      field_component
      published_at
    }
  }
`
